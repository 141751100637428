var checkUpdate = function () {
  fetch('/app.version.json?t=' + Date.now(), {
    method: 'get',
  }).then(response => {
    response.json().then(res => {
      if (res.versionBuild !== VERSION_APP_BUILD) {
        myConfirm(function () {
          localStorage.setItem("appVersion", res.versionBuild)
          window.location.reload(true);
        });
      }
    });
  });

  setTimeout(checkUpdate, 1000 * 60 * 10);
};
checkUpdate();

function myConfirm(callback) {
  let modalRoot = document.createElement('div');
  modalRoot.id = "my-dialog-confirm";
  modalRoot.className = "ui page modals dimmer transition visible active";
  modalRoot.setAttribute('style', "display: flex !important;");
  let modal = document.createElement('div');
  modal.className = "ui modal transition visible active tiny"

  let header = document.createElement('div');
  header.classList.add('header');
  header.textContent = "Обновление приложения";
  modal.appendChild(header);

  let content = document.createElement('div');
  content.classList.add('content');
  content.textContent = "Доступна новая версия приложени. Обновить и перезагрузить приложение?";
  modal.appendChild(content);

  let actions = document.createElement('div');
  actions.classList.add('actions');
  modal.appendChild(actions);

  function callbackConfirm() {
    let modal = document.getElementById('my-dialog-confirm');
    document.body.removeChild(modal);
    document.body.classList.remove('modal-open');
    callback();
  }
  function decline() {
    let modal = document.getElementById('my-dialog-confirm');
    document.body.removeChild(modal);
    document.body.classList.remove('modal-open');
  }

  let mfc = document.createElement('button');
  mfc.className = "ui positive button";
  mfc.textContent = 'Да, перезагрузить';
  mfc.addEventListener('click', callbackConfirm);

  actions.appendChild(mfc);

  let mfd = document.createElement('button');
  mfd.className = "ui  negative button";
  mfd.textContent = 'Нет, не перезагружать';
  mfd.addEventListener('click', decline);

  actions.appendChild(mfd);

  modalRoot.appendChild(modal);

  document.body.appendChild(modalRoot);
  document.body.classList.add('dimmable');
  document.body.classList.add('dimmed');

}

function notifyMe(params) {

  var options = {
    body: params.message,
    tag: params.id,
    
   // icon: icon
};

  // Проверка поддержки браузером уведомлений
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Проверка разрешения на отправку уведомлений
  else if (Notification.permission === "granted") {
    // Если разрешено, то создаём уведомление
    var notification = new Notification(params.title,options);
  }

  // В противном случае, запрашиваем разрешение
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // Если пользователь разрешил, то создаём уведомление
      if (permission === "granted") {
        var notification = new Notification(params.title,options);
      }
    });
  }

  // В конечном счёте, если пользователь отказался от получения
  // уведомлений, то стоит уважать его выбор и не беспокоить его
  // по этому поводу.
}
window.notifyMe = notifyMe;